import { CurrentMerchantAction, CurrentMerchantActionTypes } from '../actions/merchant.actions';
import { initialCurrentMerchantState } from '../state/merchant.state';

export function CurrentMerchantReducer(state = initialCurrentMerchantState, action: CurrentMerchantAction) {
    switch (action.type) {
        case CurrentMerchantActionTypes.AddMerchant: {
            return {
                ...state, merchant: action.payload
            };
        }

        case CurrentMerchantActionTypes.AddProduct: {
            return {
                ...state, product: action.payload
            };
        }

        case CurrentMerchantActionTypes.SetCategory: {
            return {
                ...state, category: action.payload,
            };
        }
        case CurrentMerchantActionTypes.SelectNearestRealtor: {
            return {
                ...state, selectedRealtor: action.payload,
            };
        }
        case CurrentMerchantActionTypes.MerchantLogIn: {
            return {
                ...state, merchant: action.payload,
            };
        }
        case CurrentMerchantActionTypes.GetAllMerchants: {
            return {
                ...state, merchants: action.payload,
            };
        }

        default:
            return state;
    }
}

import { Injectable } from '@angular/core';
import {Marker} from "@/_models/marker.model";
import {ApiService} from "@/_services/api.service";
import {MapsAPILoader} from "@agm/core";
import {SelectNearestRealtor} from "@/_store/actions/merchant.actions";
import {Realtor} from "@/_models/realtor.model";
import {SharedService} from "@/_services/shared.service";

@Injectable({
  providedIn: 'root'
})
export class GeoService {
    private initMerchant = {
        login: 'merchant'  + Math.floor(Math.random() * 1000),
        password: '1111111',
        profile: {
            name: 'Merchant' + Math.floor(Math.random() * 1000),
            age: 30,
            email: 'example@example.com'
        }
    };
  constructor(private apiService: ApiService,
              private mapsAPILoader: MapsAPILoader,
              private sharedService: SharedService
  ) { }


    findMerchantGeoPosition(latitude, longitude) {
        const geocoder = new google.maps.Geocoder();
        const LatLng = new google.maps.LatLng(latitude, longitude);

        geocoder.geocode({
            location: LatLng
        }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const details = results[0].address_components;
                    let country;
                    details.forEach((item, i) => {
                        item.types.forEach((elem, j) => {
                            if (item.types[j] === 'country') {
                                country = item.short_name;
                            }
                        });
                    });

                    console.log({country});
                    this.sharedService.addMerchantLocation(country);
                } else {
                    console.error('No results found');
                }
            } else {
                console.error('Geocoder failed due to: ' + status);
            }
        });
    }


    findMarkerAdminUnit(marker: Marker, type: string, realtorMarkersLocations?) {
        const geocoder = new google.maps.Geocoder();
        const LatLng = new google.maps.LatLng(marker.latitude, marker.longitude);

        geocoder.geocode({
            location: LatLng
        }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const details = results[0].address_components;
                    let town;
                    let country;
                    // console.log(JSON.stringify(details));
                    details.forEach((item, i) => {
                        item.types.forEach((elem, j) => {
                            if (item.types[j] === 'locality') {
                                town = item.short_name;
                            }
                            if (item.types[j] === 'country') {
                                country = item.long_name;
                            }
                        });
                    });
                    if (type === 'user') {
                        this.sharedService.changeUserMarkersLocation({marker, country, town});
                    } else {
                        realtorMarkersLocations.push({marker, country, town});
                        this.sharedService.changeRealtorMarkersLocation(realtorMarkersLocations);
                    }
                } else {
                    console.error('No results found');
                }
            } else {
                console.error('Geocoder failed due to: ' + status);
            }
        });
    }

    public getSameTownMarkers(realtorsMarkers: {marker: Marker, town: string, country: string}[],
                             userMarkers: {marker: Marker, town: string, country: string}) {
        return realtorsMarkers.filter(marker => marker.town === userMarkers.town);
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: '[rsl-tab-about]',
    templateUrl: './tab-about.component.html',
    styleUrls: ['./tab-about.component.css']
})
export class TabAboutComponent implements OnInit {

    constructor() { }

    ngOnInit() { }

}

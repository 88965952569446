import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {ConfigService} from "@/_services/config.service";
import {ApiService} from "@/_services/api.service";
import {select, Store} from "@ngrx/store";
import {IAppState} from "@/_store/app.state";
import {GetCurrentMerchant} from "@/_store/selectors/merchant.selector";
import {Merchant} from "@/_models/merchant.model";
import {GeoService} from "@/_services/geo.service";
import {SharedService} from "@/_services/shared.service";

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.css']
})
export class AuthDialogComponent implements OnInit, OnDestroy {
  isSignIn = true;
  registrationError$: Observable<string>;
  signUpMessage$: Observable<{message: string, email: string}>;

  private subscription = new Subscription();
  private merchantLocation: string;

  constructor(public dialogRef: MatDialogRef<AuthDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { isSignIn: boolean },
              private apiService: ApiService,
              private store: Store<IAppState>,
              private sharedService: SharedService,
              private cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (!this.data.isSignIn) {
      this.isSignIn = false;
    }
      this.subscription.add(this.store.pipe(select(GetCurrentMerchant))
          .subscribe((merchant: Merchant) => {
              if (merchant) {
                  this.handleCloseDialog();
              }
          }));
    this.subscription.add(this.sharedService.merchantGeolocation$.subscribe(location => {
        this.merchantLocation = location;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  formSubmitHandler(formValue: any, actionType: 'signIn' | 'signUp') {
    if (actionType === 'signIn')  {
        const params = {
            login: formValue.email,
            password: formValue.password
        };
        this.apiService.loginMerchant(ConfigService.Settings.api.qaBotToken, params);
    } else {
        const merchant = {
            login: formValue.email,
            password: formValue.password,
            profile: {
                name: `${formValue.fullName}`,
                age: '',
                email: formValue.email,
                country: this.merchantLocation
            }
        };
        this.apiService.addMerchant(ConfigService.Settings.api.aqBotId, merchant);
    }
  }

  onAddAccount() {
    this.isSignIn = false;
  }

  onSignIn(): void {
    this.isSignIn = true;
  }

  handleCloseDialog() {
    this.dialogRef.close();
  }
}

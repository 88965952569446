import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Marker} from "@/_models/marker.model";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
    private realtorMarkersLocation = new BehaviorSubject([]);
    realtorMarkersLocationObs = this.realtorMarkersLocation.asObservable();

    private userMarkerLocation = new BehaviorSubject(null);
    userMarkerLocationObs = this.userMarkerLocation.asObservable();

    private merchantGeolocation = new BehaviorSubject(null);
    merchantGeolocation$ = this.merchantGeolocation.asObservable();


    constructor() { }

    changeRealtorMarkersLocation(obj: {marker: Marker, town: string, country: string}[]) {
        this.realtorMarkersLocation.next(obj);
    }

    changeUserMarkersLocation(obj: {marker: Marker, town: string, country: string}) {
        this.userMarkerLocation.next(obj);
    }

    addMerchantLocation(country: string) {
        this.merchantGeolocation.next(country);
    }
}

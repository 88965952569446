import {Merchant} from '@/_models/merchant.model';
import {Product} from "@/_models/product.model";
import {Realtor} from "@/_models/realtor.model";

export interface CurrentMerchantState {
    merchant: Merchant;
    category: string;
    product: Product;
    selectedRealtor: Realtor;
    loginError: string;
    signUpError: string;
    merchants: Merchant[];
}

export const initialCurrentMerchantState: CurrentMerchantState = {
    merchant: null,
    category: null,
    product: null,
    selectedRealtor: null,
    loginError: null,
    signUpError: null,
    merchants: []
};

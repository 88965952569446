import { createSelector } from '@ngrx/store';
import { IAppState } from '../app.state';
import { CurrentMerchantState } from '../state/merchant.state';

const currentMerchantState = (state: IAppState) => state.currentMerchant;

export const GetCurrentMerchant = createSelector(
    currentMerchantState,
    (state: CurrentMerchantState) => state.merchant
);

export const GetMerchantToken = createSelector(
    currentMerchantState,
    (state: CurrentMerchantState) => state.merchant.token
);

export const GetProductCategory = createSelector(
    currentMerchantState,
    (state: CurrentMerchantState) => state.category
);

export const GetMerchantProduct = createSelector(
    currentMerchantState,
    (state: CurrentMerchantState) => state.product
);
export const GetSelectedRealtor = createSelector(
    currentMerchantState,
    (state: CurrentMerchantState) => state.selectedRealtor
);
export const GetAllMerchants = createSelector(
    currentMerchantState,
    (state: CurrentMerchantState) => state.merchants
);


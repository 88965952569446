import { Marker } from '../../_models/marker.model';

export interface MapMarkersState {
    realtors_ids: any[];
    merchant_ids: any[];
    markers: Marker[];
    tmpMarker?: Marker;
    activeMarker: Marker;
    qaMarkers: Marker[];
}

export const initialMapMarkersState: MapMarkersState = {
    realtors_ids: [],
    merchant_ids: [],
    markers: [],
    tmpMarker: null,
    activeMarker: null,
    qaMarkers: []
};

import {Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, NgZone, OnDestroy} from '@angular/core';
import {MapsAPILoader} from "@agm/core";
import {select, Store} from "@ngrx/store";
import {IAppState} from "@/_store/app.state";
import {SetMainLocation} from "@/_store/actions/mainlocation.actions";
import {
    GetAllMerchants,
    GetCurrentMerchant,
    GetProductCategory,
    GetSelectedRealtor
} from "@/_store/selectors/merchant.selector";
import {ProductInfo, QuestionInfoAttributes} from "@/_models/product.model";
import {Marker} from "@/_models/marker.model";
import {AddTmpMarker} from "@/_store/actions/markers.actions";
import {combineLatest, Observable, Subscription} from "rxjs";
import {Realtor} from "@/_models/realtor.model";
import {ApiService} from "@/_services/api.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GetCurrentUser, GetUserCategory} from "@/_store/selectors/currentuser.selector";
import {SharedService} from "@/_services/shared.service";
import {MatDialog} from "@angular/material/dialog";
import {AuthDialogComponent} from "@/auth/auth-dialog/auth-dialog.component";
import {Merchant} from "@/_models/merchant.model";
import {GetTmpMarker, GetVisibleQAMapMarkers} from "@/_store/selectors/markers.selector";
import {GetMapRealtors} from "@/_store/selectors/realtors.selector";
import {ConfigService} from "@/_services/config.service";

@Component({
  selector: '[rsl-tab-question]',
  templateUrl: './tab-question.component.html',
  styleUrls: ['./tab-question.component.css']
})
export class TabQuestionComponent implements OnInit, OnDestroy {
    private $value!: boolean;
    questionForm: FormGroup;

    @ViewChild('search', { static: false }) public searchElement: ElementRef;
    @Input()
    set value(val) {
        this.$value = val;
        this.onChange(this.$value);
    }

    get value(): boolean {
        return this.$value;
    }
    private geoCoder: google.maps.Geocoder;

    private subscription = new Subscription();
    private selectedRealtor: Realtor;
    private categoryId: string;
    public authDialogTemplate = AuthDialogComponent;
    public questionsInfo: QuestionInfoAttributes[];
    public realtors: Realtor[];
    public currentRealtor: Realtor;
    public currentMerchant: Merchant;
    public tmpMarker: Marker;
    public withReplies: boolean = false;
    public merchants: Merchant[];
    onChange(_: any): any {}

    onClick(event: MouseEvent): void {
        if ((event.target as HTMLElement).tagName === 'A') {
            return;
        }
        this.withReplies = !this.withReplies;

        this.value = !this.value;
    }

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private store: Store<IAppState>,
              private apiService: ApiService,
              private sharedService: SharedService,
              private matDialog: MatDialog) { }

  ngOnInit() {
      this.questionForm = new FormGroup({
          question: new FormControl('', [Validators.required]),
          // email: new FormControl('', [Validators.required, Validators.email]),
      });
      // if (this.searchElement) {
          this.mapsAPILoader.load().then(() => {
              this.geoCoder = new google.maps.Geocoder;
              const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
                  types: ['address']
              });

              autocomplete.addListener('place_changed', () => this.searchLocation(autocomplete));
          });
      // }

      this.subscription.add(this.store.select(GetSelectedRealtor).subscribe(selectedRealtor => {
        this.selectedRealtor = selectedRealtor;
      }));
      this.subscription.add(this.store.select(GetProductCategory).subscribe(categoryId => {
        this.categoryId = categoryId;
      }));
      this.subscription.add(this.sharedService.userMarkerLocationObs.subscribe(marker => {
          if (this.searchElement && marker) {
              this.searchElement.nativeElement.value = `${marker.town}, ${marker.country}`;
          }
      }));

      this.subscription.add(this.store.pipe(select(GetCurrentMerchant))
          .subscribe((merchant: Merchant) => {
              this.currentMerchant = merchant;
              if (merchant) {
                  this.addQuestion(merchant);
              }
      }));
      this.subscription.add(this.store.pipe(select(GetMapRealtors))
          .subscribe(realtors => {
              this.realtors = realtors;
      }));
      this.subscription.add(this.store.pipe(select(GetVisibleQAMapMarkers)).subscribe((markers: Marker[]) => {
         this.questionsInfo = markers.map(item => ({...item.attributes, id: item.id}));
      }));
      this.subscription.add(this.store.select(GetCurrentUser).subscribe(user => {
          this.currentRealtor = user;
      }));
      this.subscription.add(this.store.select(GetTmpMarker).subscribe(marker => {
          this.tmpMarker = marker;
      }));

      this.subscription.add(this.store.select(GetTmpMarker).subscribe(marker => {
          this.tmpMarker = marker;
      }));
      this.subscription.add(this.store.select(GetAllMerchants).subscribe(merchants => {
          this.merchants = merchants;
      }));

      this.apiService.getAllMerchantsCall(ConfigService.Settings.api.aqBotId, ConfigService.Settings.api.qaUserToken);


  }

  ngOnDestroy() {
        this.subscription.unsubscribe();
  }

    searchLocation(autocomplete: google.maps.places.Autocomplete) {
        this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
                return;
            }

            this.store.dispatch(new SetMainLocation({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                zoom: 14
            }));
            const newMarker: Marker = {
                id: 0,
                realtor_id: null,
                title: 'tmp',
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                draggable: true,
                attributes: {
                    place: place.address_components[1].short_name
                }
            };
            this.store.dispatch(new AddTmpMarker(newMarker));
        });
    }

    onSendQuestion() {
        if (this.currentMerchant) {
            this.addQuestion(this.currentMerchant);
        } else {
            this.openMerchantLogin();
        }
    }

    openMerchantLogin($event?: any) {
        if (this.currentRealtor || this.currentMerchant) {
            return;
        }
        if ($event) {
            $event.preventDefault();
        }

        const isSignIn = true;
        const afterCloseEvent: Observable<any> = this.matDialog.open(this.authDialogTemplate, {
            id: '1',
            backdropClass: 'auth-overlay',
            panelClass: 'auth-dialog-container',
            data: {isSignIn},
        }).afterClosed();
        const afterCloseEventSubscription: Subscription = afterCloseEvent.subscribe(
            (res: { value: { email: string; password: string }, actionType: 'signIn' | 'signUp' }) => {
                afterCloseEventSubscription.unsubscribe();
            });
    }

    addQuestion(merchant: Merchant) {
        if (merchant && this.questionForm.value.question) {
            const product: ProductInfo = {
                category_id: ConfigService.Settings.api.aqCategory,
                name: this.questionForm.value.question,
                attributes: {
                    answers: [],
                    realtors_ids: [],
                    name: this.questionForm.value.question,
                    price: 1,
                    image: null,
                    is_question: true,
                    question: this.questionForm.value.question,
                    merchant_id: merchant.merchant_id
                }
            };
            this.apiService.addProduct(product, merchant.token);
            setTimeout(() => {
                this.questionForm.reset();
            }, 100);
        }
    }

    addAnswerHandler(attributes: QuestionInfoAttributes) {
        const product: ProductInfo = {
            category_id: ConfigService.Settings.api.aqCategory,
            bot_id:  ConfigService.Settings.api.aqBotId,
            name: attributes.name,
            attributes
        };
        this.apiService.updateProduct(product.attributes.id, product, ConfigService.Settings.api.qaUserToken, this.currentRealtor.name);
    }

}

import { Location } from '../../_models/location.model'
// Oakville Ontario
export const initialMainLocationState: Location = {
    latitude: 43.465890,
    longitude: -79.682060,
    zoom:10
};

export function getInitialMainLocationState():Location {
    return initialMainLocationState
}

import { Action } from '@ngrx/store';
import {Realtor} from "@/_models/realtor.model";
import {Merchant} from "@/_models/merchant.model";

export class CurrentMerchantAction implements Action {
  readonly type;
  payload?: any;
}

export enum CurrentMerchantActionTypes {
  AddMerchant = '[CurrentMerchant] AddMerchant',
  AddProduct = '[CurrentMerchant] AddProduct',
  SetCategory = '[CurrentMerchant] SetCategory',
  SelectNearestRealtor = '[CurrentMerchant] SelectNearestRealtor',
  MerchantLogIn = '[CurrentMerchant] MerchantLogIn',
  MerchantLogInError = '[CurrentMerchant] MerchantLogInError',
  // MerchantSignUp = '[CurrentMerchant] MerchantSignUp',
  MerchantSignUpError = '[CurrentMerchant] MerchantSignUpError',
  GetAllMerchants = '[CurrentMerchant] Merchants',
}

export class AddMerchant implements CurrentMerchantAction {
    public readonly type = CurrentMerchantActionTypes.AddMerchant;
    constructor(public payload: any) {}
}

export class AddProduct implements CurrentMerchantAction {
    public readonly type = CurrentMerchantActionTypes.AddProduct;
    constructor(public payload: any) {}
}

export class SetMerchantCategory implements CurrentMerchantAction {
    public readonly type = CurrentMerchantActionTypes.SetCategory;
    constructor(public payload: any) {}
}
export class SelectNearestRealtor implements CurrentMerchantAction {
    public readonly type = CurrentMerchantActionTypes.SelectNearestRealtor;
    constructor(public payload: Realtor) {}
}

export class MerchantLogIn implements CurrentMerchantAction {
    public readonly type = CurrentMerchantActionTypes.MerchantLogIn;
    constructor(public payload: any) {}
}

export class MerchantLogInError implements CurrentMerchantAction {
    public readonly type = CurrentMerchantActionTypes.MerchantLogInError;
    constructor(public payload: string) {}
}

export class MerchantSignUpError implements CurrentMerchantAction {
    public readonly type = CurrentMerchantActionTypes.MerchantSignUpError;
    constructor(public payload: string) {}
}

export class GetAllMerchants implements CurrentMerchantAction {
    public readonly type = CurrentMerchantActionTypes.GetAllMerchants;
    constructor(public payload: Merchant[]) {}
}

import {Component, EventEmitter, Input,  OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.css']
})
export class SignInFormComponent implements OnInit, OnDestroy {
  @Input() isPage: boolean;
  @Input() loginError: {error: string, email: string};
  @Output() formSubmit: EventEmitter<{ email: string, password: string }> = new EventEmitter<{ email: string, password: string }>();
  @Output() addAccount: EventEmitter<void> = new EventEmitter<void>();
  @Output() recoverPassword: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

  public authValidationForm: FormGroup;
  public emailValidator: FormControl = new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ]
  );
  public passwordValidator: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(50)
  ]);


  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.authValidationForm = this.formBuilder.group(
      {
        email: this.emailValidator,
        password: this.passwordValidator
      });
  }

  ngOnDestroy() {
  }

  submitForm($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.authValidationForm.valid) {
      this.formSubmit.emit(this.authValidationForm.value);
    }
  }

  createAccount() {
     this.addAccount.emit();
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';

import { AgmCoreModule } from '@agm/core';
import { StripeCheckoutModule } from 'ng-stripe-checkout';
import { TakeSpaceComponent } from './_common/take-space/take-space.component';
import { HeaderComponent } from './_common/header/header.component';
import { FooterComponent } from './_common/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MapComponent } from './map/map.component';
import { TabMapComponent } from './map/tab-map/tab-map.component';
import { TabPricingComponent } from './map/tab-pricing/tab-pricing.component';
import { TabAboutComponent } from './map/tab-about/tab-about.component';
import { TabContactComponent } from './map/tab-contact/tab-contact.component';
import { TabPlaceadComponent } from './map/tab-placead/tab-placead.component';
import { TabLoginComponent } from './map/tab-login/tab-login.component';
import { TabRegisterComponent } from './map/tab-register/tab-register.component';
import { TabPrivacyComponent } from './map/tab-privacy/tab-privacy.component';
import { TabQuestionComponent } from './map/tab-question/tab-question.component';
import { GmapComponent } from './map/gmap/gmap.component';
import { RealtorComponent } from './map/tab-map/realtor/realtor.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { appReducers } from './_store/app.reducer';

import { ConfigService } from './_services/config.service';
import { TabLogoutComponent } from './map/tab-logout/tab-logout.component';
import { DetailsComponent } from './map/tab-map/details/details.component';
import { HomeComponent } from './home/home.component';
import { ListQuestionComponent } from './map/components/list-question/list-question.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {SignInFormComponent} from "@/auth/sign-in-form/sign-in-form.component";
import {SignUpFormComponent} from "@/auth/sign-up-form/sign-up-form.component";
import {AuthDialogComponent} from "@/auth/auth-dialog/auth-dialog.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDividerModule} from "@angular/material/divider";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { PricingCardComponent } from './map/tab-pricing/pricing-card/pricing-card.component';
import {AgmMarkerClustererModule, ClusterManager} from "@agm/markerclusterer";

export function initConfig(appConfig: ConfigService) {
    return () => appConfig.load();
}

    @NgModule({
    declarations: [
        AppComponent,
        TakeSpaceComponent,
        HeaderComponent,
        FooterComponent,
        MapComponent,
        TabMapComponent,
        TabPricingComponent,
        TabAboutComponent,
        TabContactComponent,
        GmapComponent,
        TabPlaceadComponent,
        TabLoginComponent,
        TabRegisterComponent,
        TabPrivacyComponent,
        TabQuestionComponent,
        RealtorComponent,
        TabLogoutComponent,
        DetailsComponent,
        HomeComponent,
        ListQuestionComponent,
        SignInFormComponent,
        SignUpFormComponent,
        AuthDialogComponent,
        PricingCardComponent
    ],
        imports: [
            BrowserModule,
            BrowserAnimationsModule,
            AppRoutingModule,
            StoreModule.forRoot(appReducers),
            HttpClientModule,
            FormsModule,
            ReactiveFormsModule,
            NgbModule,
            StripeCheckoutModule,
            AgmCoreModule.forRoot({
                apiKey: 'AIzaSyDP6Ces1p8-GIfHQqeu6fQalR4cfDHKjMo',
                libraries: ['places']
            }),
            !environment.production ? StoreDevtoolsModule.instrument() : [],
            AgmMarkerClustererModule,
            BrowserAnimationsModule,
            NgMultiSelectDropDownModule.forRoot(),
            MatButtonModule,
            MatIconModule,
            MatFormFieldModule,
            MatDividerModule,
            MatDialogModule,
            MatInputModule,
            MatSnackBarModule,
        ],
    providers: [
        ConfigService,
        { provide: APP_INITIALIZER, useFactory: initConfig, deps: [ConfigService], multi: true },
        ClusterManager
    ],
    entryComponents: [AuthDialogComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }

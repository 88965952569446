import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import {Subject, Observable, Subscription, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, skip} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Location } from '../../_models/location.model'
import { MapBounds } from '../../_models/map-bounds.model'
import { Marker } from '../../_models/marker.model'
import { Realtor } from '@/_models/realtor.model'
import { SetMapBounds } from '../../_store/actions/mapbounds.actions';
import { GetMainLocation } from '../../_store/selectors/mainlocation.selector';
import { SetMainLocation } from '../../_store/actions/mainlocation.actions';
import {
    GetVisibleMapMarkers,
    GetTmpMarker,
    GetVisibleQAMapMarkers,
    GetVisibleRealtorsIds
} from '../../_store/selectors/markers.selector';
import {
    UpdateTmpMarkerCoords,
    DeleteMapMarker,
    ClearActiveMarker,
    SetActiveMarker, AddTmpMarker
} from '../../_store/actions/markers.actions';
import {GetMapRealtors, GetVisibleRealtors} from '../../_store/selectors/realtors.selector';
import { SetActiveRealtor, ClearActiveRealtor } from '../../_store/actions/realtors.actions';
import { GetCurrentUser } from '@/_store/selectors/currentuser.selector'
import { IAppState } from '../../_store/app.state'

import { ApiService } from '@/_services/api.service';
import { AuthenticationService } from '@/_services/authentication.service';
import {MapsAPILoader} from "@agm/core";
import {Merchant} from "@/_models/merchant.model";
import {SharedService} from "@/_services/shared.service";
import {GeoService} from "@/_services/geo.service";
import {GetCurrentMerchant} from "@/_store/selectors/merchant.selector";
import {initialMainLocationState} from "@/_store/state/mainlocation.state";
import {ConfigService} from "@/_services/config.service";
@Component({
    selector: '[rsl-gmap]',
    templateUrl: './gmap.component.html',
    styleUrls: ['./gmap.component.css']
})
export class GmapComponent implements OnInit, OnDestroy {
    @Input() public mapHeight: number;
    @Input() public activeLink: string;
    @Output() toMap: EventEmitter<string> = new EventEmitter<string>();
    @Output() toggleQAModal: EventEmitter<boolean> = new EventEmitter<boolean>();
    public mainLocation: Location = initialMainLocationState;
    private _mapBounds: MapBounds;
    private _mapBoundsChanged: Subject<string> = new Subject<string>();
    public tmpMarker: Marker;
    public realtyMarkers: Marker[];
    public qaMarkers: Marker[];
    public mapLocation: any[] = [];
    public tmpMarkerIcon: any = {
        url: this.getTmpMarkerIcon,
        scaledSize: {
            width: 20,
            height: 23.5
        }
    };
    private mapRealtors: Realtor[];
    public user: any;
    private subscription = new Subscription();
    private currentUser: Realtor;
    private currentMerchant: Merchant;
    private userLocation: any;
    private paymentConfig: any;
    private demoRealtor = 'F0CAKTZVAZ1AW7X';


    constructor(
        private store: Store<IAppState>,
        private authenticationService: AuthenticationService,
        private apiService: ApiService,
        private mapsAPILoader: MapsAPILoader,
        private sharedService: SharedService,
        private cd: ChangeDetectorRef,
        private geoService: GeoService) {
        this.paymentConfig = ConfigService.Settings.pricing;

    }

    ngOnInit() {
        if ("geolocation" in navigator){
            navigator.geolocation.getCurrentPosition(pos => this.setUserLocation(pos));
        }

        this.subscription.add(this.store.pipe(select(GetTmpMarker))
            .subscribe(tmpMarker => {
                this.tmpMarker = tmpMarker;
            }));

        this.subscription.add(this.store.pipe(select(GetMainLocation))
            .subscribe(mainLocation => {
                this.mapLocation.push(mainLocation);
                // skip 3 for now as we need to find user location and also load Oakville location
                // it must be removed in future when we will go to user location
                const notUserLocation = !!this.userLocation && (this.userLocation.latitude !== mainLocation.latitude) &&
                    (this.userLocation.longitude !== mainLocation.longitude) || !this.userLocation;
                if ((this.mapLocation.length <= 3 && notUserLocation) || this.mapLocation.length > 3 ) {
                    this.mainLocation = mainLocation;
                    this.cd.detectChanges();
                }
            }));

        this.subscription.add(combineLatest(
            this.store.pipe(select(GetVisibleRealtors)),
            this.store.pipe((select(GetVisibleRealtorsIds))),
            this.store.pipe(select(GetVisibleMapMarkers))
        ).subscribe(([realtors, ids, markers]) => {
            this.mapRealtors = realtors.filter(realtor => ids.includes(realtor.id) && realtor.plan.id &&
                (realtor.plan.id === this.paymentConfig.plans.business_monthly.id ||
                    realtor.plan.id === this.paymentConfig.plans.business_yearly.id ||
                    realtor.plan.id === this.paymentConfig.plans.professional_monthly.id ||
                    realtor.plan.id === this.paymentConfig.plans.professional.id) || realtor.id === this.demoRealtor);
            const mapRealtorsIds = this.mapRealtors.map(realtor => realtor.id);

            this.realtyMarkers = markers.filter(marker => mapRealtorsIds.includes(marker.realtor_id));
        }));


      //  bounds change

        this.apiService.loadMarkers();
        this.apiService.loadRealtors();
        this.toggleQAModal.emit(true);

        this.subscription.add(this.sharedService.userMarkerLocationObs.
        subscribe(( userMarkerLocation: {marker: Marker, country: string, town: string}) => {
            if (userMarkerLocation) {
                const newMarker = userMarkerLocation.marker;
                newMarker.attributes = {place: userMarkerLocation.town};
                this.store.dispatch(new AddTmpMarker(newMarker));
            }
        }));

        this._mapBoundsChanged.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(bounds => this.boundsChanged(bounds));
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(pos => this.mainLocationChanged(pos));
        }

        this.subscription.add(this.store.pipe(select(GetVisibleQAMapMarkers))
            .subscribe(qaMarkers => {
                this.qaMarkers = qaMarkers;
            }));

        this.subscription.add(this.store.select(GetCurrentUser).subscribe(user => {
            if (user) {
                this.user = user;
            }
        }));

        this.store.select(GetCurrentUser).subscribe(user => {
            if (user) {
                this.currentUser = user;
            }
        });

        this.subscription.add(this.store.pipe(select(GetCurrentMerchant))
            .subscribe((merchant: Merchant) => {
                this.currentMerchant = merchant;
            }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onMapClick($event) {
        if (this.activeLink === 'map' || this.currentUser && this.currentUser.id) {
            return;
        }

        const newMarker: Marker = {
            id: 0,
            realtor_id: null,
            title: 'tmp',
            latitude: $event.coords.lat,
            longitude: $event.coords.lng,
            draggable: true
        };

        this.mapsAPILoader.load().then(() => {
            this.geoService.findMarkerAdminUnit(newMarker, 'user');
            this.store.dispatch(new AddTmpMarker(newMarker));
        });
    }

    public _boundsChange(mapBounds) {
        this._mapBoundsChanged.next(mapBounds);
    }

    public boundsChanged(mapBounds) {
        this.store.dispatch(new SetMapBounds(mapBounds));
        this._mapBounds = mapBounds;
    }

    public setUserLocation(centerPosition) {
        if (!this.currentUser && !this.currentMerchant) {
            this.userLocation = {latitude: centerPosition.coords.latitude, longitude: centerPosition.coords.longitude};

            this.mapsAPILoader.load().then(() => {
                this.geoService.findMerchantGeoPosition(centerPosition.coords.latitude, centerPosition.coords.longitude);
            });

            // current logic to load Oakville location, remove dispatch when user location needed only
            this.store.dispatch(new SetMainLocation({
                latitude: initialMainLocationState.latitude,
                longitude: initialMainLocationState.longitude,
                zoom: 10
            }));
        }
    }

    public mainLocationChanged(centerPosition) {
        this.store.dispatch(new SetMainLocation({
            latitude: centerPosition.coords.latitude,
            longitude: centerPosition.coords.longitude,
            zoom: 15
        }));
    }

    public dragendTmpMarker(event) {
        this.store.dispatch(new UpdateTmpMarkerCoords({
            latitude: event.coords.lat,
            longitude: event.coords.lng,
        }))
    }

    public setRealtorActive(realtorId, marker) {

        const timeout = this.isMobile() ? 0 : 100;

        this.toMap.emit()
        setTimeout(() => {
            this.store.dispatch(new ClearActiveRealtor())
        }, timeout)
        setTimeout(() => {
            this.store.dispatch(new SetActiveRealtor(realtorId))
        }, timeout)
    }

    public setMarkerActive(id){
        const timeout = this.isMobile() ? 0 : 100;

        this.toMap.emit()
        setTimeout(() => {
            this.store.dispatch(new ClearActiveMarker())
        }, timeout)
        setTimeout(() => {
            this.store.dispatch(new SetActiveMarker(id))
        }, timeout)
    }

    public getRealtorName(realtorId) {
        if (this.mapRealtors) {
            const realtor = this.mapRealtors.find(function(e) {return e.id === realtorId})

            if (realtor) {
                return realtor.name
            }
        }

        return ' '
    }

    public deleteAd(marker) {
        this.apiService.deleteMarker(marker).then(()=>{
            this.store.dispatch(new DeleteMapMarker(marker))
            this.authenticationService.refreshMe()
        }).catch((error) => {
            console.log(error)
        });

        return false
    }

    public isMobile(){
        return !window.matchMedia('(min-width: 992px)').matches
    }

    public onDeleteQAMarker(marker: Marker) {}

    get getTmpMarkerIcon(): string {
        if (this.user) {
            return '/assets/images/logo-pin.png';
        } else {
            return '/assets/images/pin-small.svg';
        }
    }
}

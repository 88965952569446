import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@/_store/app.state'
import { ConfigService } from '@/_services/config.service';
import { first } from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {AddQAMapMarker, SetMapMarkers, SetQAMapMarkers, UpdateQAMarker} from '@/_store/actions/markers.actions';

import { Realtor } from '@/_models/realtor.model';
import { Marker } from '@/_models/marker.model';
import { RoleEnum } from '@/_models/role.model';
import { SetUserCategory, SetUserBot } from '@/_store/actions/currentuser.actions';
import { GetUserCategory, GetAccessToken, GetCurrentUser, GetUserBot } from '@/_store/selectors/currentuser.selector'
import { GetTmpMarker, GetMapRealtorsIds } from '@/_store/selectors/markers.selector'
import { SetRealtors } from '@/_store/actions/realtors.actions'
import { ClearTmpMarker, AddMapMarker, DeleteMapMarker } from '@/_store/actions/markers.actions'
import {
    AddMerchant,
    AddProduct,
    MerchantLogIn,
    MerchantLogInError, MerchantSignUpError,
    SetMerchantCategory,
    GetAllMerchants
} from "@/_store/actions/merchant.actions";
import {Product, ProductInfo} from "@/_models/product.model";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private apiConfig: any;
    private user: Realtor;
    private accessToken: string;

    constructor(private httpClient: HttpClient,
                private snackBar: MatSnackBar,
                private store: Store<IAppState>) {
        this.apiConfig = ConfigService.Settings.api;

        combineLatest(
            this.store.select(GetAccessToken),
            this.store.select(GetCurrentUser)
        ).subscribe(([token, user]) => {
            this.user = user
            this.accessToken = token
        })
    }

    private apiCall(endpoint: string, method: string, data:any={}, headers:any={}, token?: string){
        const url = this.apiConfig.apiUrl+endpoint;
        data.access_token = this.accessToken;

        if (url.includes('api/merchant/')) {
            data.access_token = token;
        }

        switch (method) {
            case 'get': {
                return this.httpClient.get<any>(url, {params: data});
            }
            case 'post': {
                return this.httpClient.post<any>(url, data, headers);
            }
            case 'put': {
                return this.httpClient.put<any>(url, data, headers);
            }
            case 'delete': {
                return this.httpClient.delete<any>(url, {headers: new HttpHeaders({'X-Access-Token': this.accessToken})})
            }
        }
    }

    public createCategory(botId){
        const user = JSON.parse(localStorage.getItem('currentUser'))
        let categoryData = {
            name: user.email+'_RSL',
            title: user.name+' RSL',
            bot_id: botId,
            attributes: {
                realtor_id: {type:'string'},
                title: {type:'string'},
                latitude: {type:'number'},
                longitude: {type:'number'},
                market_report: {type:'string'},
            },
        }

        this.apiCall('category', 'post', categoryData).subscribe((response)=>{
            if(response.status){
                this.store.dispatch(new SetUserCategory(response.category_id))
                return true;
            } else {
                return false;
            }
        })
    }

    public getCategory(botId: string){
        this.apiCall('categories/'+botId, 'get').subscribe((response)=>{
            if(response.status){
                let botCategory = response.categories.find(cat => 'realtor_id' in cat.attributes)
                if(botCategory){
                    this.store.dispatch(new SetUserCategory(botCategory.category_id))
                    return true;
                } else {
                this.createCategory(botId)
                    return false;
                }
            } else {
                return false;
            }
        })
    }

    public getBot(){
        this.apiCall('bots', 'get').subscribe((response)=>{
            if(response.status){
                let bot = this._findBot(response.bots)
                if(bot){
                    this.store.dispatch(new SetUserBot(bot.bot_id))
                    this.getCategory(bot.bot_id)
                }
                return true;
            } else {
                return false;
            }
        })
    }

    public makeConnection(){
        this.store.select(GetUserBot).pipe(first()).subscribe(
            (botId) => {
                this.apiCall('connection/widget/' + botId, 'put', {
                    provider_data:{
                        token: botId+'widgettoken',
                        settings:{
                            position:"right",
                            width: 320,
                            height: 480,
                            resizeable: false,
                            color: '#f3705b',
                            theme: 'rounded',
                            type: 'button'
                        }
                },
                }).subscribe((response)=>{
                    if(response.status){
                        this.activateBot(botId)
                        this.enableBotNotifications(botId)
                        this.setBotUsername(botId, this.user.name)
                        this.setBotCompany(botId, this.user.meta.companyname)
                        return true;
                    } else {
                        return false;
                    }
                })
            })
    }

    public activateBot(botId:string){
        this.apiCall('bot/active/'+botId, 'post', {
                active:true
            }).subscribe((response)=>{
                return true
            })
    }

    public enableBotNotifications(botId:string){
        this.httpClient.post<any>(this.apiConfig.apiUrl+'bot/settings/'+botId, {
            notifications:true
        }, {headers: new HttpHeaders({'X-Access-Token': this.accessToken})}).subscribe((response)=>{
            return true
        })
    }

    public setBotUsername(botId:string, username:string) {
        this.apiCall('bot/'+botId, 'put', {
            name:username
        }).subscribe((response)=>{
            return true
        })
    }

    public setBotCompany(botId:string, company:string) {
        this.apiCall('variable/'+botId+'/company', 'put', {
            value:company
        }).subscribe((response)=>{
            return true
        })
    }

    async addMarker(title, flyerImg, marketReportFile){
        combineLatest(
            this.store.select(GetUserCategory),
            this.store.select(GetTmpMarker)
        ).subscribe(
            ([categoryId, marker]) => {
                // add product
                this.apiCall('product', 'post', {
                    category_id: categoryId,
                    attributes: {
                        name: title,
                        realtor_id: this.user.id,
                        image: flyerImg,
                        market_report: marketReportFile,
                        title: title,
                        latitude: marker.latitude,
                        longitude: marker.longitude,
                    }
                }).subscribe((response)=>{
                    if(response.status){
                        this.store.dispatch(new ClearTmpMarker())
                        this.store.dispatch(new AddMapMarker({
                                id: response.product_id,
                                realtor_id: this.user.id,
                                image: flyerImg,
                                market_report: marketReportFile,
                                title: title,
                                latitude: marker.latitude,
                                longitude: marker.longitude,
                                draggable: false
                            }))
                        return true
                    } else {
                        return false
                    }
                })
            },
            error => {
                console.log(error)
                return false
            });
    }

    public loadMarkers(){
        this.apiCall('products/search', 'post', {
                portal_id: this.apiConfig.portalId,
                limit: 10000
            }).subscribe((response) => {
                if (response.status) {
                    const realtorsMarkers = response.products.filter(item => !item.attributes.is_question);
                    const qaMarkers =  response.products.filter(item => item.attributes.is_question);
                    this.store.dispatch(new SetMapMarkers(realtorsMarkers.map(product => {
                        {
                            return <Marker>{
                                id: product.id,
                                realtor_id: product.attributes.realtor_id,
                                image: product.attributes.image,
                                title: product.attributes.title,
                                latitude: product.attributes.latitude,
                                longitude: product.attributes.longitude,
                                market_report: product.attributes.market_report,
                                draggable: false
                            };
                        }
                    })));
                    this.store.dispatch(new SetQAMapMarkers(qaMarkers.map(product => {
                        {
                            return <Marker> {
                                id: product.id,
                                realtors_ids: product.attributes.realtors_ids,
                                image: product.attributes.image,
                                title: product.attributes.title,
                                latitude: product.attributes.lat,
                                longitude: product.attributes.lng,
                                market_report: product.attributes.market_report,
                                draggable: false,
                                attributes: product.attributes
                            };
                        }
                    })));
                    return true;
                } else {
                    return false;
                }
            });
    }

    public loadRealtors(){
        this.store.pipe(select(GetMapRealtorsIds))
            .subscribe(realtorsIds => {
                if(realtorsIds.length){
                    this.apiCall('users/search/public', 'get', {
                        portal_id: this.apiConfig.portalId,
                        limit: realtorsIds.length,
                        ids: realtorsIds.join(','),
                    }).subscribe((response)=>{
                        if(response.status){
                            this.store.dispatch(new SetRealtors(response.users.map(user => {
                                {
                                    return <Realtor>{
                                        id: user.account_id,
                                        bot_id: user.meta.bot_id,
                                        widgetToken: user.meta.bot_id+'widgettoken',
                                        name: user.name,
                                        meta: user.meta,
                                        role: RoleEnum.UserRole,
                                        portal:this.apiConfig.portalId,
                                        plan: user.plan
                                    }
                                }
                            })))
                            return true
                        } else {
                            return false
                        }
                    })
                }
            })
    }
    // https://api.brn.ai/subscription-plan/PlanId/subscribe
    public subscribePlanConfirm(planId, stripeToken){
        return this.apiCall('subscription-plan/'+planId+'/subscribe', 'post', {
            token: stripeToken
        }).toPromise()
    }

    public uploadFile(botId, file, name){
        const formData: FormData = new FormData();
        const headers = new HttpHeaders()
        const type = file.type.split('/')[0] === 'image' ? 'image' : 'file';
        headers.append('Content-Type', 'multipart/form-data')
        formData.append('fileKey', file, name);
        return this.apiCall('attachment/' + type + '/' + botId, 'post', formData, { headers: headers}).toPromise()
    }

    public deleteMarker(marker) {
        return this.apiCall('product/' + marker.id, 'delete').toPromise()
    }

    public sendContactForm(email, message){
        const data = {
            email,
            message,
            template: "contact_reply",
            source: "contact"
        };
        const headers = new HttpHeaders({'Portal-ID': this.apiConfig.portalId})
        return this.apiCall('contact', 'post', data, { headers })
    }

    private _findBot(bots:any[]){
        return bots.find(bot => {
            return bot.portal_id === this.apiConfig.portalId
        })
    }

    private _findConnection(connections:any[]){
        return connections.find(connection => {
            return connection.provider == 'widget'
        })
    }


    public addMerchant(botId: string, merchant) {
        // VKJUYPKZOE - category id
       this.createMerchant(botId, merchant).
        subscribe((merchantRes) => {

            if (merchantRes.status) {
                this.store.dispatch(new AddMerchant(merchantRes));
                this.getAllMerchantsCall(ConfigService.Settings.api.aqBotId, ConfigService.Settings.api.qaUserToken);
                return true;

            } else {
                this.store.dispatch(new MerchantSignUpError(merchantRes.error));
                this.snackBar.open(merchantRes.error, '', {duration: 5000});
                return false;
            }
        });
    }

    public loginMerchant(botToken: string, params: {login: string, password: string}) {
        this.merchantLogin(params, botToken).
        subscribe((result) => {
            if (result.status) {
                localStorage.setItem('currentMerchant', JSON.stringify(result));

                this.store.dispatch(new MerchantLogIn(result));
                return true;
            } else {
                this.store.dispatch(new MerchantLogInError(result.error));
                this.snackBar.open(result.error, '', {duration: 5000});
                return false;
            }
        });
    }

    public createMerchant(botId, merchant) {
        return this.apiCall(`merchant/create/${botId}`, 'post', merchant);
    }

    public addProduct(product: ProductInfo, token) {
        this.store.pipe(select(GetTmpMarker))
            .subscribe(marker => {
                if (!marker) {
                    return false;
                }

                product.attributes.lat = marker.latitude.toString();
                product.attributes.lng = marker.longitude.toString();
                product.attributes.place = marker.attributes.place;
                this.createProduct(product, token).subscribe((response: Product) => {
                    if (response.status) {
                        this.store.dispatch(new AddProduct(response.product_id));
                        this.store.dispatch(new ClearTmpMarker());
                        this.store.dispatch(new AddQAMapMarker({
                            id: response.product_id,
                            image: '',
                            market_report: '',
                            title: product.attributes.question,
                            latitude: marker.latitude,
                            longitude: marker.longitude,
                            draggable: false,
                            attributes: product.attributes
                        }));

                        return true;
                    } else {
                        return false;
                    }
                });
            });
    }

    public sendEmail(merchantEmail: string, AgentName: string) {
        const product = {
            "user_id" : "4122714396",
            "bot_id": "JCEYJYF7ZG",
            type: "text",
            content: {
                "text": `sendmail ${merchantEmail} ${AgentName}`
            }
        };
        return this.apiCall(`message/receive/widget`, 'post', product);
    }

    public updateProduct(productId: string, product: ProductInfo, token: string, realtorName: string) {
        this.store.select(GetAccessToken).subscribe(tokenI => {
            this.updateProductRequest(productId, product, token).subscribe((response: Product) => {
                if (response.status) {
                    this.store.dispatch(new AddProduct(response.product_id));
                    this.store.dispatch(new UpdateQAMarker({
                        id: response.product_id,
                        image: '',
                        market_report: '',
                        title: product.attributes.question,
                        latitude: parseInt(product.attributes.lat, 0),
                        longitude: parseInt(product.attributes.lng, 0),
                        draggable: false,
                        attributes: product.attributes
                    }));
                    this.sendEmailToMerchant(ConfigService.Settings.api.qaBotToken, product.attributes.merchant_id, realtorName);
                    return true;
                } else {
                    return false;
                }
            });
        });
    }

    public sendEmailToMerchant(botToken, id, agentName) {
        this.getMerchantEmail(botToken, id).subscribe(res => {
            this.sendEmail(res.profile.email, agentName).subscribe(result => {
                console.log(result);
            });
        });
    }

    public getAllMerchantsCall(botId, botToken) {
        this.getAllMerchants(botId, botToken).subscribe((res: any) => {
            if (res.status) {
                this.store.dispatch(new GetAllMerchants(res.merchants));
            }
        });
    }


    public createProduct(product: ProductInfo, token): Observable<Product> {
        return this.apiCall(`api/product`, 'post', product, {headers: new HttpHeaders({'X-Access-Token': token})});
    }

    public getCategories(botId) {
        return this.apiCall(`categories/${botId}`, 'get');
    }

    //user token
    public updateProductRequest(productId: string, product: ProductInfo, token: string) {
        return this.apiCall(`product/${productId}`, 'put', product, {headers: new HttpHeaders({'X-Access-Token': token})});
    }

    public merchantLogin(loginInfo: {login: string, password: string}, botToken) {
        return this.apiCall(`api/merchant/login`, 'post', loginInfo,
            {headers: new HttpHeaders({'X-Access-Token': botToken})});

    }

    getMerchantEmail(botToken: string, id: string) {
        return this.apiCall(`api/merchant/${id}`, 'get', {} , {}, botToken);
    }

    getAllMerchants(botId: string, botToken: string) {
        return this.httpClient.get<any[]>(`${this.apiConfig.apiUrl}merchants/${botId}?limit=50&offset=0`, {
            headers: {
                'X-Access-Token': botToken,
            },
        });
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//Routes
import { MapComponent } from './map/map.component'


const routes: Routes = [
    { 
        path:  '', 
        component: MapComponent,
        pathMatch:  'full',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {Product, ProductInfo, QuestionInfoAttributes, RealtorAnswer} from "@/_models/product.model";
import {Realtor} from "@/_models/realtor.model";
import {FormControl, Validators} from "@angular/forms";
import {Merchant} from "@/_models/merchant.model";
import {Marker} from "@/_models/marker.model";
import {ConfigService} from "@/_services/config.service";

@Component({
    selector: "app-list-question",
    templateUrl: "./list-question.component.html",
    styleUrls: ["./list-question.component.css"],
})
export class ListQuestionComponent implements OnInit, OnChanges {
    @Input() questionsInfo: QuestionInfoAttributes[];
    @Input() realtors: Realtor[];
    @Input() currentRealtor: Realtor;
    @Input() currentMerchant: Merchant;
    @Input() withReplies: boolean;
    @Input() allMerchants: Merchant[];
    @Output() addAnswer: EventEmitter<QuestionInfoAttributes> = new EventEmitter<QuestionInfoAttributes>();
    @Output() openLoginForm: EventEmitter<void> = new EventEmitter<void>();
    answer = new FormControl('', Validators.required);
    private defaultAvatar = 'https://api.brn.ai/img/default-client-avatar.png';
    activeQuestionIndex!: number;
    public paymentConfig: any;
    initQuestions =  [
        {
            title: "Should I sell my house in this market, or wait? What are the pro’s and con’s.",
            quantity: 10,
            merchantName: 'Robert',
            merchantCountry: 'gb'
        },
        {
            title: "What is your real estate fee if I sell my home?",
            quantity: 10,
            merchantName: 'Andrew',
            merchantCountry: 'us'
        },
        {
            title: "Does it make a difference in using a staging company when I sell my home?",
            quantity: 10,
            merchantName: 'John',
            merchantCountry: 'ca'
        },
        {
            title: "Should I rent my home right now, and how do I do that with an agent?",
            quantity: 10,
            merchantName: 'William',
            merchantCountry: 'au'
        },
    ];
    questions = [];

    constructor() {
        this.paymentConfig = ConfigService.Settings.pricing;
    }

    ngOnInit() {
     this.questions = this.initQuestions;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentRealtor && !this.currentRealtor) {
            this.activeQuestionIndex = null;
        }
        if (changes.questionsInfo || changes.realtors || changes.currentMerchant || changes.allMerchants) {
            if (!this.realtors.length || !this.questionsInfo.length || !this.allMerchants.length) {
                return;
            }
            this.questions = this.initQuestions;
            let formattedQA = this.questionsInfo.map((item: QuestionInfoAttributes) => {
                const merchantInfo: any = this.getMerchantInfo(item);

                return  {
                    title: item.question,
                    quantity: item.realtors_ids.length,
                    merchantName: merchantInfo && merchantInfo.profile.name ? merchantInfo.profile.name.split(' ')[0] : '',
                    merchantCountry: merchantInfo && merchantInfo.profile.country ? merchantInfo.profile.country.toLowerCase() : '',
                    messages: item.answers.length ? item.answers.filter((message: RealtorAnswer) => {
                        if (this.currentRealtor && message.realtor_id && (message.realtor_id === this.currentRealtor.id) ||
                            this.currentMerchant && (this.currentMerchant.merchant_id === item.merchant_id)) {
                            return message;
                        }
                    }).map((answer: RealtorAnswer) => {
                       const currentRealtor = this.currentRealtor ? this.currentRealtor : this.getRealtorById(answer.realtor_id);
                       return {
                            user: {
                                name: currentRealtor && currentRealtor.name ? currentRealtor.name : '',
                                avatar: currentRealtor && currentRealtor.meta.avatar ? currentRealtor.meta.avatar : this.defaultAvatar,
                            },
                            message: answer.answer,
                        };
                    }) : [],
                    marker: item
               };
            });
            formattedQA = formattedQA.reverse();
            this.questions = [...this.questions, ...formattedQA];
        }
    }

    getRealtorById(id: string): Realtor {
     return this.realtors.filter(item => item.id === id)[0];
    }

    toggleChat(question, index: number): void {
        if (!this.currentRealtor && !this.currentMerchant) {
            this.openLoginForm.emit();
            return;
        }
        if (!this.isAvailable(question, index)) {
            return;
        }
        if (this.activeQuestionIndex === index) {
            this.activeQuestionIndex = undefined;
            return;
        }
        this.activeQuestionIndex = index;
    }

    onAddAnswer(question: {marker: QuestionInfoAttributes}) {
        const updatedQuestion = question.marker;
        if (!updatedQuestion.realtors_ids.includes(this.currentRealtor.id)) {
            updatedQuestion.realtors_ids.push(this.currentRealtor.id);
        }
        updatedQuestion.answers.push({realtor_image: this.currentRealtor.avatar,
            realtor_name: this.currentRealtor.name, answer: this.answer.value, realtor_id: this.currentRealtor.id});
        this.addAnswer.emit(updatedQuestion);
        this.answer.setValue(null);
    }

    isAvailableForMerchant(question: {marker: QuestionInfoAttributes}): boolean {
        return this.currentMerchant && (question.marker.merchant_id === this.currentMerchant.merchant_id) && !!question.marker.answers.length;
    }

    // add due to plan && (this.currentRealtor.plan.mode === 'subscription' && this.currentRealtor.plan.status !== 'trial')
    isAvailableForRealtor(question) {
        return this.currentRealtor && !this.currentMerchant && (question.quantity < 10);
    }

    isAvailable(question, i): boolean {
        return (i > 3) && (this.isAvailableForMerchant(question) || this.isAvailableForRealtor(question));
   }

    get isSubscription(): boolean {
        return this.currentRealtor.plan && !!this.currentRealtor.plan.id;
    }

    get getFilteredQuestions() {
        return this.withReplies ? this.questions.filter(question => !!question.quantity) : this.questions;
    }

    getMerchantInfo(question: QuestionInfoAttributes): Merchant {
        const filteredArray =
        this.allMerchants && question && question.merchant_id && this.allMerchants.length && this.allMerchants.filter(merchant => {
            return merchant.merchant_id === question.merchant_id;
        });
        return filteredArray &&  filteredArray.length && filteredArray[0];
    }
}

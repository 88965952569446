import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Subscription} from 'rxjs';
import {ApiService} from "@/_services/api.service";
import {ConfigService} from "@/_services/config.service";

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.css']
})
export class SignUpFormComponent implements OnInit, OnDestroy {
  @Input() registrationError: string;
  @Input() signUpMessage: {message: string, email: string};
  @Output() formSubmit: EventEmitter<{ email: string, password: string }> = new EventEmitter<{ email: string, password: string }>();
  @Output() signIn: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('positionInput', {static: false}) positionInput: ElementRef;
  public authValidationForm: FormGroup;
  public emailValidator: FormControl = new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ]
  );
  public passwordValidator: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(15)
  ]);
  public fullNameValidator: FormControl = new FormControl('', Validators.required);
  private subscription = new Subscription();

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.authValidationForm = this.formBuilder.group(
      {
        email: this.emailValidator,
        password: this.passwordValidator,
        fullName: this.fullNameValidator,
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submitForm() {
      if (this.authValidationForm.valid) {
          this.formSubmit.emit(this.authValidationForm.value);
      }
  }

  onSignIn() {
     this.signIn.emit();
  }
}
